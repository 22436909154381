import http from './http'
import { omitBy, isNil } from 'lodash'

export function query (input) {
  let query
  if (input.query) {
    query = input.query
  } else {
    query = input
  }
  return http.get('/locations/places/query', { params: { input: query } })
}

export function nearby (params) {
  params = omitBy(params, isNil)

  if (params.place_id && params.location) {
    delete params.location
  }
  params.rankby = 'distance'

  return http.get('/locations/places/nearby', {
    params: params
  })
}

export function textsearch (query, params) {
  return http.get('/locations/places/textsearch', {
    params: {
      query,
      ...params
    }
  })
}

export function details (placeId) {
  return http.get('/locations/places/details', { params: { place_id: placeId } })
}
