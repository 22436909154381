<template>
  <q-item>
    <q-item-section v-if="photo" avatar>
      <q-img :src="photo" class="bg-white" height="72px" width="72px" />
    </q-item-section>
    <q-item-section v-else avatar>
      <q-avatar :icon="'fas fa-' + icon.name" color="primary" square size="72px" />
    </q-item-section>
    <q-item-section top>
      <q-item-label class="primary text-weight-medium">
        {{ name }}
      </q-item-label>
      <q-item-label caption>
        {{ vicinity }}
      </q-item-label>
      <q-rating
        v-if="rating"
        v-model="rating"
        :max="5"
        color="primary"
        readonly
      />
    </q-item-section>
  </q-item>
</template>

<script type="text/javascript">
export default {
  props: [
    'placeId',
    'photos',
    'name',
    'vicinity',
    'rating',
    'icon'
  ],
  computed: {
    photo () {
      return this.photos
        ? `${process.env.VUE_APP_API_URL}/locations/places/photo/${this.photos[0].photo_reference}`
        : null
    }
  }
}
</script>
<style lang="stylus">
  .item
    height 56px
    border-bottom 1px solid lighten(black, 55)
    .q-btn
      width 96px

  .topBar
    height 50px
  .mobilityNearbyList
    z-index 10
    width 800px
    height 350px
    position absolute
    background-color white
    bottom 0
    right calc(50% - 400px)
    .q-btn
      font-size .75em
      padding 2px 4px 2px 4px

  .loadingContainer
    .spinner
      position absolute
      left calc(50% - 32px)
      top calc(50% - 32px)

  @media only screen and (max-width: 800px)
    .mobilityNearbyList
      width 100%
      right 0
</style>
