<template>
  <q-page class="discovery row">
    <q-scroll-area class="col-4 q-px-md q-pb-sm">
      <m-select-query
        v-model="search"
        :label="$t('location_search')"
        stack-label
        :placeholder="$t('search')"
        icon="place"
        class="q-mt-md q-pb-sm q-px-sm"
        :query="autocomplete"
        clearable
      />
      <place-card v-for="item in items" :key="item.place_id" v-bind="item" class="row" @click.native="$emit('show', item)" />
    </q-scroll-area>
    <m-gmaps ref="gmap" :markers="markers" :center="location" :zoom="18" class="col-8" />
  </q-page>
</template>

<script type="text/javascript">
import { query } from 'api/places'
import placeCard from './placeCard.vue'
import { MSelectQuery, MGmaps } from 'components/'

export default {
  components: {
    placeCard,
    MSelectQuery,
    MGmaps
  },
  props: ['items', 'type', 'location', 'loading'],
  data () {
    return {
      search: null
    }
  },
  computed: {
    markers () {
      let colors = {
        restaurants: '616161',
        events: 'f57f17',
        parking: '2196f3',
        'coffee-shop': 'd32f2f',
        'ev-station': 'B0BEC8',
        'petrol-station': 'fbc02d',
        'cash-machine': '1B5E1E'
      }
      return this.items
        ? this.items.map(item => {
          return {
            key: item.place_id,
            position: item.geometry.location,
            icon: `https://www.google.co.uk/maps/vt/icon/name=assets/icons/poi/quantum/container_background-2-medium.png,assets/icons/poi/quantum/container-2-medium.png&highlight=ffffff,${colors[this.type] || '99cc00'},ffffff&color=ff000000?scale=0.75`
          }
        })
        : []
    }
  },
  watch: {
    search (obj) {
      this.$emit('location-change', obj.value)
    }
  },
  methods: {
    autocomplete (terms) {
      return query(terms)
        .then(response => {
          return response.data.map(location => {
            return {
              label: location.description,
              value: location.place_id,
              icon: 'place'
            }
          })
        })
    }
  }
}
</script>
