<template>
  <q-page padding style="padding-top: 34px">
    <m-select-query
      v-model="search"
      :label="$t('location_search')"
      stack-label
      :placeholder="$t('search')"
      icon="place"
      :query="autocomplete"
      clearable
      class="col-12 q-pa-md"
    />
    <place-card v-for="item in items" :key="item.place_id" v-bind="item" class="row" @click.native="$emit('show', item)" />
    <q-page-sticky expand position="top">
      <q-tabs
        active-color="primary"
        class="text-white shadow-1 bg-white text-faded col-12 discovery-nav"
      >
        <q-route-tab
          :to="{ name: 'Discovery', params: { type: 'parking' } }"
          :replace="true"
          name="parking"
          icon="fas fa-parking"
        />
        <q-route-tab
          :to="{ name: 'Discovery', params: { type: 'ev-station' } }"
          :replace="true"
          name="charging-station"
          icon="fas fa-charging-station"
        />
        <q-route-tab
          :to="{ name: 'Discovery', params: { type: 'petrol-station' } }"
          :replace="true"
          name="petrol-station"
          icon="fas fa-gas-pump"
        />
        <q-route-tab
          :to="{ name: 'Discovery', params: { type: 'cash-machine' } }"
          :replace="true"
          name="atm"
          icon="fas fa-money-bill"
        />
        <q-route-tab
          :to="{ name: 'Discovery', params: { type: 'coffee-shop' } }"
          :replace="true"
          name="coffee-shop"
          icon="fas fa-coffee"
        />
        <q-route-tab
          :to="{ name: 'Discovery', params: { type: 'restaurant' } }"
          :replace="true"
          name="restaurants"
          icon="fas fa-utensils"
        />
      </q-tabs>
    </q-page-sticky>
  </q-page>
</template>

<script type="text/javascript">
import { query } from 'api/places'
import { MSelectQuery } from 'components/'
import placeCard from './placeCard.vue'
export default {
  components: {
    placeCard,
    MSelectQuery
  },
  props: ['items', 'type'],
  data () {
    return {
      search: null
    }
  },
  watch: {
    search (obj) {
      this.$emit('location-change', obj.value)
    }
  },
  methods: {
    autocomplete (terms) {
      return query(terms)
        .then(response => {
          return response.data.map(location => {
            return {
              label: location.description,
              value: location.place_id,
              icon: 'place'
            }
          })
        })
    }
  }
}
</script>
<style lang="stylus">
  .discovery-nav
    .q-tab
      padding: 0 8px
    .q-icon
      font-size: 22px
</style>
