<template>
  <div>
    <component
      :is="$q.screen.lt.md ? 'mobile' : 'desktop'"
      :type="type"
      :location="location"
      :items="items"
      :loading="loading"
      :class="{
        'light-dimmed': loading
      }"
      @location-change="locationChange"
      @show="show"
    />
    <m-discovery-detail-modal ref="detail" />
  </div>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import { nearby, details } from 'api/places'
import desktop from './desktop.vue'
import mobile from './mobile.vue'
import { MDiscoveryDetailModal } from 'components/'

export default {
  components: {
    desktop,
    mobile,
    MDiscoveryDetailModal
  },
  props: ['type'],
  data () {
    return {
      items: null,
      loading: false,
      location: {
        lat: 51.500766,
        lng: -0.124576
      }
    }
  },
  computed: {
    ...mapGetters({
      latLng: 'geolocate/latLng'
    })
  },
  created () {
    if (this.latLng.latitude && this.latLng.longitude) {
      this.location.lat = this.latLng.latitude
      this.location.lng = this.latLng.longitude
    }
    this.getItems()
  },
  beforeRouteUpdate (to, from, next) {
    this.getItems(to.params.type).then(() => {
      next()
    })
  },
  methods: {
    show (item) {
      details(item.place_id)
        .then(response => {
          this.$refs.detail.showing = response.data
          this.$refs.detail.showing.rating = item.rating
          this.$refs.detail.$refs.modal.show()
        })
    },
    locationChange (placeId) {
      if (!placeId) return
      details(placeId)
        .then(response => {
          this.location.lat = response.data.geometry.location.lat
          this.location.lng = response.data.geometry.location.lng
          this.getItems()
        })
    },
    getItems (type) {
      this.loading = true
      return nearby({
        location: `${this.location.lat},${this.location.lng}`,
        keyword: type || this.type
      })
        .then((response) => {
          setTimeout(() => {
            this.loading = false
            this.items = response.data
          }, 350)
        }).catch(() => {
          setTimeout(() => {
            this.loading = false
          }, 350)
        })
    }
  }
}
</script>
